import React from 'react';
// Components
import Img from 'gatsby-image';
// Images
import quoteIcon from '../../images/icons/quote.svg';
// Style
import {
  Author,
  Container,
  ImagePaper,
  ImageWrapper,
  Quote,
  QuoteIcon,
  QuoteWrapper,
  StyledLink,
  Text
} from './ImageQuote.styles';

const ImageQuote = ({ author,imageOrientation,img,imgAlt='',slug,text,variant }) => {

  return(
    <Container>
    {variant === "left" &&
      <ImageWrapper orientation={imageOrientation}>
        <ImagePaper>
          <Img fluid={img} alt={imgAlt} />
        </ImagePaper>
      </ImageWrapper>
    }
      <QuoteWrapper variant={variant}>
        <Quote>
          <QuoteIcon src={quoteIcon} alt="quote icon" />
          <Text>{text}</Text>
          <Author>{author}</Author>
        </Quote>
        <StyledLink to={`/post/${slug}`}>Read the full story</StyledLink>
      </QuoteWrapper>
      {variant === "right" &&
        <ImageWrapper variant={variant}>
          <ImagePaper>
            <Img fluid={img} alt={imgAlt} />
          </ImagePaper>
        </ImageWrapper>
      }
    </Container>
  )
}

export default ImageQuote;