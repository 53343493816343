import React, { Fragment } from "react"
// Components
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container1200 } from '../components/Container1200';
import { ImageQuote } from '../components/ImageQuote';
import { PageTitle } from '../components/PageTitle';
// Style
import {
  Divider
} from '../styles/pages-styles/collection.styles';

export const query = graphql`
  query CollectionQuery {
    allSanityPost(sort: {order: DESC, fields: publishedAt}) {
      edges {
        node {
          _id
          mainImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          imageOrientation
          imgAlt
          quote
          author {
            name
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

const CollectionPage = ({ data }) => {

  return (
    <Layout>
      <SEO
        image={data.allSanityPost.edges[0].node.mainImage.asset.fluid.src}
        path="/collection"
        title="Collection of Stories"
      />
        <Container1200>
          <PageTitle>The Collection</PageTitle>
            {data.allSanityPost.edges.map(({ node: post }, index) => {
              return (
                <Fragment key={post.slug.current}>
                  <ImageQuote
                    author={post.author.name}
                    imageOrientation={post.imageOrientation}
                    img={post.mainImage.asset.fluid}
                    imgAlt={post.imgAlt}
                    slug={post.slug.current}
                    text={post.quote}
                    variant={index%2 === 0 ? "left" : "right"}
                  />
                  {index !== (data.allSanityPost.edges.length - 1) &&
                    <Divider />
                  }
                </Fragment>
              )
            })}
        </Container1200>
    </Layout>
  )
}

export default CollectionPage
