import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media(min-width: 768px) {
    flex-direction: row;
    padding: 0 20px;
  }

  @media(min-width: 1240px) {
    padding: 0;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ orientation }) => (orientation==='h') && `
    width: 200px;

    @media(min-width: 768px) {
      width: 280px;
    }

    @media(min-width: 992px) {
      width: 370px;
    }
  `}

  ${({ orientation }) => (orientation!=='h') && `
    width: 170px;

    @media(min-width: 768px) {
      width: 240px;
    }

    @media(min-width: 992px) {
      width: 300px;
    }
  `}
`;

export const ImagePaper = styled.div`
  position: relative;
  background-color: ${c.grayPaper};
  padding: 8px;
  width: 100%;
  box-shadow: 2px 3px 3px rgba(0,0,0,0.4);
  transform: rotate(4deg);
  border: 1px solid #ddd;
  margin-bottom: 50px;

  @media(min-width: 768px) {
    margin-bottom: 0;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${c.grayPaper};
    transform: rotate(-10deg);
    z-index: 1;
    box-shadow: 1px 3px 3px rgba(0,0,0,0.4);
    border: 1px solid #ddd;
  }

  .gatsby-image-wrapper {
    z-index: 2;
    position: relative;
    width: 100%;
    height: auto;
    transform: rotate(-10deg);
  }
`;

export const QuoteWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 1;

  @media (min-width: 768px) {
    order: 0;
  }

  ${({ variant }) => (variant==='left') && `

    @media(min-width: 768px) {
      margin-left: 90px;
    }
  `}

  ${({ variant }) => (variant==='right') && `

    @media(min-width: 768px) {
      margin-right: 90px;
    }
  `}
`;

export const Quote = styled.div`
  position: relative;
  background-color: ${c.grayPaper};
  border-radius: 8px;
  padding: 30px;

  @media(min-width: 992px) {
    padding: 60px;
  }
`;

export const Text = styled.p`
  font-size: 2.4rem;
  font-style: italic;
  line-height: 119%;
  margin-bottom: 20px;

  @media(min-width: 992px) {
    font-size: 3rem;
  }
`;

export const Author = styled.p`
  font-family: Thasadith;
  font-size: 2rem;
  font-style: italic;
  text-align: right;

  @media(min-width: 992px) {
    font-size: 2.6rem;
  }
`;

export const QuoteIcon = styled.img`
  position: absolute;
  height: auto;
  top: -21px;
  left: -11px;
  width: 46px;

  @media(min-width: 992px) {
    top: -28px;
    left: -16px;
    width: 64px;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  padding: 10px 0 10px 24px;
  color: ${c.grayText};
  font-size: 1.9rem;
  text-decoration: none;
  font-style: italic;
  transition: color .15s linear;

  @media(min-width: 992px) {
    font-size: 2.2rem;
  }

  &:hover {
    color: ${c.blackText}
  }
`;
