import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Divider = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 0;

  @media(min-width: 768px) {
    padding: 60px 0;
  }

  @media(min-width: 992px) {
    padding: 80px 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 160px;
    height: 2px;
    background-color: ${c.grayLine};
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;
